import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import type { Amount } from '@orus.eu/amount'
import { paymentRecurrenceLabel, type PaymentRecurrence } from '@orus.eu/dimensions'
import {
  Avatar,
  Badge,
  Button,
  ButtonLink,
  Chip,
  colorTokens,
  DownloadButtonWithHref,
  EmptyState,
  spacing,
  Text,
  useEnqueueToast,
  type ChipProps,
} from '@orus.eu/pharaoh'
import { useNavigate, type NavigateOptions } from '@tanstack/react-router'
import type { ReactElement } from 'react'
import { memo, useCallback } from 'react'
import { useCopyToClipboard } from '../../../../../../lib/clipboard-util'
import { usePermissions } from '../../../../../../lib/use-permissions'
import { useBuildUrl } from '../../../../../../use-build-url'
import { TableCellAmount } from '../../../../../molecules/table-elements/table-cell-amount'
import { TableCellHeader } from '../../../../../molecules/table-elements/table-cell-header'

export type RenewalEndorsementTableRowData = {
  dueDate: string
  subscriptionId?: string
  status: { label: string } & Omit<ChipProps, 'size' | 'children'>
  paymentAmount?: Amount
  paymentRecurrence?: PaymentRecurrence
  downloadLink?: string
  fileName?: string
  downloadTooltip?: string
  routeToNavigateTo?: string
  routeToDetailedSheet?: NavigateOptions
  token?: string
  timelineId?: string
}

export const RenewalEndorsementTable = memo<{
  tableData: RenewalEndorsementTableRowData[]
  emptyStateSubtitle: string
  CreateNewEndorsementButton?: ReactElement
}>(function RenewalEndorsementTable({ tableData, emptyStateSubtitle, CreateNewEndorsementButton }): ReactElement {
  const navigate = useNavigate()
  const { enqueueToast } = useEnqueueToast()
  const { buildUrl } = useBuildUrl()
  const permissions = usePermissions()

  const { copyToClipboard } = useCopyToClipboard()

  const copyLink = useCallback(
    (token: string) => {
      copyToClipboard(
        buildUrl({
          to: '/s/r/$token',
          params: { token },
        }),
      )
      enqueueToast('Lien du renouvellement copié', { variant: 'success' })
    },
    [copyToClipboard, buildUrl, enqueueToast],
  )

  return (
    <>
      {tableData.length > 0 ? (
        <TableContainer
          sx={{
            border: `1px solid ${colorTokens['color-stroke-base']}`,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHeader>Date d&apos;effet</TableCellHeader>
                <TableCellHeader>Montant</TableCellHeader>
                <TableCellHeader>Fréquence de paiement</TableCellHeader>
                <TableCellHeader>Statut</TableCellHeader>
                <TableCellHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((tableRowData, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Text variant="body2">{tableRowData.dueDate}</Text>
                  </TableCell>
                  {tableRowData.paymentAmount ? <TableCellAmount amount={tableRowData.paymentAmount} /> : <TableCell />}
                  <TableCell>
                    {tableRowData.paymentRecurrence ? (
                      <Badge>{paymentRecurrenceLabel.feminine.capitalized[tableRowData.paymentRecurrence]}</Badge>
                    ) : undefined}
                  </TableCell>
                  <TableCell>
                    <Chip size="large" {...tableRowData.status}>
                      {tableRowData.status.label}
                    </Chip>
                  </TableCell>
                  <StyledTableCell>
                    <div
                      css={css`
                        display: flex;
                        justify-content: flex-end;
                        gap: ${spacing[30]};
                      `}
                    >
                      {tableRowData.timelineId &&
                      tableRowData.subscriptionId &&
                      permissions.permissions.includes('timeline.read') ? (
                        <ButtonLink
                          variant="secondary"
                          size="small"
                          icon="comment-regular"
                          avatarPosition="left"
                          title="Accéder à la conversation"
                          to={`/bak/v2-pending-subscriptions/$subscriptionId`}
                          params={{ subscriptionId: tableRowData.subscriptionId }}
                          search={{ tab_id: 'conversation', timeline_id: tableRowData.timelineId }}
                        />
                      ) : undefined}
                      {tableRowData.downloadLink && tableRowData.fileName ? (
                        <DownloadButtonWithHref
                          variant="secondary"
                          size="small"
                          title={tableRowData.downloadTooltip}
                          href={tableRowData.downloadLink}
                          fileName={tableRowData.fileName}
                          iconOnly
                          icon="arrow-down-to-line-regular"
                        />
                      ) : undefined}
                      {tableRowData.token ? (
                        <Button
                          variant="secondary"
                          size="small"
                          icon="link-regular"
                          avatarPosition="left"
                          onClick={() => tableRowData.token && copyLink(tableRowData.token)}
                        />
                      ) : undefined}
                      {tableRowData.routeToDetailedSheet ? (
                        <ButtonLink
                          variant="secondary"
                          size="small"
                          title="Accéder à la fiche détaillée"
                          icon="ballot-regular"
                          {...tableRowData.routeToDetailedSheet}
                          ariaLabel="Accéder à la fiche détaillée"
                        />
                      ) : undefined}
                      {tableRowData.routeToNavigateTo ? (
                        <Button
                          variant="secondary"
                          size="small"
                          avatar={<Avatar icon="arrow-right-regular" />}
                          onClick={() => void navigate({ to: tableRowData.routeToNavigateTo })}
                        />
                      ) : undefined}
                    </div>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState icon="file-check-light" subtitle={emptyStateSubtitle} style="neutral">
          {CreateNewEndorsementButton}
        </EmptyState>
      )}
    </>
  )
})

const StyledTableCell = styled(TableCell)`
  padding-right: ${spacing[50]};
`
