import { css } from '@emotion/react'
import type { InvoicingItem } from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import { DownloadButton, DownloadButtonWithHref, getFileDisplayName, spacing, useLanguage } from '@orus.eu/pharaoh'
import { memo, useCallback } from 'react'
import { dynamicFileNames } from '../../../lib/download-filenames'
import { getAdminMedDownloadLink } from '../../../lib/download-links'
import { useDownloadInvoices } from '../../../lib/hooks/use-download-invoices'
import { statusAllowsMed } from './types'

type InvoiceDocumentsDownloadButtonsProps = {
  invoicingItem: InvoicingItem
}
export const InvoiceDocumentsDownloadButtons = memo<InvoiceDocumentsDownloadButtonsProps>(
  function InvoiceDocumentDownloadButtons({ invoicingItem }) {
    const language = useLanguage()
    const invoiceId = invoicingItem.invoice.invoiceId

    const { download, downloadInProgress } = useDownloadInvoices({ invoiceId })

    const handleDownloadClick = useCallback(() => {
      if (invoicingItem.type !== 'emitted') return

      download(
        invoicingItem.paymentStatus === 'paid' ? 'downloadReceipt' : 'downloadInvoice',
        invoicingItem.paymentStatus === 'paid'
          ? dynamicFileNames.receipts(invoicingItem.invoiceNumber, language)
          : dynamicFileNames.invoice(invoicingItem.invoiceNumber, language),
      )
    }, [download, invoicingItem, language])

    return (
      <div
        css={css`
          display: flex;
          gap: ${spacing[30]};
        `}
      >
        {invoicingItem.type === 'emitted' && statusAllowsMed[invoicingItem.paymentStatus] ? (
          <DownloadButtonWithHref
            variant="secondary"
            size="small"
            href={getAdminMedDownloadLink(invoiceId)}
            fileName="MED.pdf"
          />
        ) : (
          <></>
        )}
        {invoicingItem.type === 'emitted' && invoicingItem.paymentStatus === 'paid' ? (
          <DownloadButton
            variant="secondary"
            size="small"
            downloadInProgress={downloadInProgress}
            onClick={handleDownloadClick}
          >
            {getFileDisplayName(dynamicFileNames.receipts(invoicingItem.invoiceNumber, language))}
          </DownloadButton>
        ) : (
          <></>
        )}
        {invoicingItem.type === 'emitted' && invoicingItem.paymentStatus !== 'paid' ? (
          <DownloadButton
            variant="secondary"
            size="small"
            onClick={handleDownloadClick}
            downloadInProgress={downloadInProgress}
          >
            {getFileDisplayName(dynamicFileNames.invoice(invoicingItem.invoiceNumber, language))}
          </DownloadButton>
        ) : (
          <></>
        )}
      </div>
    )
  },
)
