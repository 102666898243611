import { type Amount, amountToString, areAmountsEqual } from '@orus.eu/amount'
import type {
  BackofficeContractDescription,
  BackofficeContractVersionDescription,
} from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager.js'
import { formatCompanyIdNumber } from '@orus.eu/company-id-number'
import type { PaymentRecurrence } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { Language } from '@orus.eu/translations'
import { dynamicFileNames } from '../../../../../../lib/download-filenames'
import { getAdminContractDownloadLink } from '../../../../../../lib/download-links'

type Change = {
  variable: string
  beforeChangeFormattedValue: string
  afterChangeFormattedValue: string
}

type FieldConfiguration<VALUE> = {
  name: string
  formatter: (value: VALUE, language: Language) => string | undefined
}

export type BackofficeEndorsement = {
  signatureId: string
  signatureTimestamp: number
  startTimestamp: number
  paymentAmount: Amount
  paymentRecurrence: PaymentRecurrence
  fileName: string
  downloadLink: string
  comment: string | undefined
  changes: Change[]
  contractVersion: BackofficeContractVersionDescription
  subscriptionId: string
  id: string | null
}

const dimensionsFormattersForDiff: {
  [key in keyof SubscriptionDimensionnedState]?: FieldConfiguration<SubscriptionDimensionnedState[key]>
} = {
  companyName: { name: 'Raison sociale', formatter: formatString },
  companyAddress: { name: 'Addresse', formatter: formatString },
  personalAddress: { name: 'Address perso', formatter: formatString },
  companyIdNumber: {
    name: 'Company ID Number',
    formatter: (companyIdNumber, language) =>
      companyIdNumber ? formatCompanyIdNumber(companyIdNumber, language) : undefined,
  },
  activity: { name: 'Activité', formatter: (activity) => activity?.displayName ?? undefined },
  allOptions: { name: 'Options', formatter: (options) => options?.join(', ') ?? undefined },
}

function computeChanges(
  version1: BackofficeContractVersionDescription,
  version2: BackofficeContractVersionDescription,
  language: Language,
): Change[] {
  const changes: Change[] = []

  for (const [key, formatter] of Object.entries(dimensionsFormattersForDiff)) {
    const dimensionName = key as keyof SubscriptionDimensionnedState
    const formattedValue1 = formatDimensionValue(version1.dimensions, dimensionName, language)
    const formattedValue2 = formatDimensionValue(version2.dimensions, dimensionName, language)
    if (formattedValue1 !== formattedValue2) {
      changes.push({
        variable: formatter?.name ?? dimensionName,
        beforeChangeFormattedValue: formattedValue1 ?? '',
        afterChangeFormattedValue: formattedValue2 ?? '',
      })
    }
  }

  const firstYearExpectedYearlyTotalPremium1 = version1.expectedYearlyPremiums.firstYear
  const firstYearExpectedYearlyTotalPremium2 = version2.expectedYearlyPremiums.firstYear
  if (!areAmountsEqual(firstYearExpectedYearlyTotalPremium1, firstYearExpectedYearlyTotalPremium2)) {
    changes.push({
      variable: 'Prix (première année)',
      beforeChangeFormattedValue: formatAmount(firstYearExpectedYearlyTotalPremium1),
      afterChangeFormattedValue: formatAmount(firstYearExpectedYearlyTotalPremium2),
    })
  }

  const subsequentYearExpectedYearlyTotalPremium1 = version1.expectedYearlyPremiums.subsequentYears
  const subsequentYearExpectedYearlyTotalPremium2 = version2.expectedYearlyPremiums.subsequentYears
  if (!areAmountsEqual(subsequentYearExpectedYearlyTotalPremium1, subsequentYearExpectedYearlyTotalPremium2)) {
    changes.push({
      variable: 'Prix (années suivantes)',
      beforeChangeFormattedValue: formatAmount(subsequentYearExpectedYearlyTotalPremium1),
      afterChangeFormattedValue: formatAmount(subsequentYearExpectedYearlyTotalPremium2),
    })
  }

  return changes
}

function formatAmount(amount: Amount) {
  return amountToString(amount, { displayDecimals: true, addCurrency: true })
}

function formatDimensionValue<K extends keyof SubscriptionDimensionnedState>(
  state: SubscriptionDimensionnedState,
  dimensionName: K,
  language: Language,
): string | undefined {
  return dimensionsFormattersForDiff[dimensionName]?.formatter(state[dimensionName], language) ?? undefined
}

function formatString(value: string | null | undefined): string | undefined {
  return value ?? undefined
}

export function getEndorsementsFromContract(
  contract: BackofficeContractDescription,
  customerEmail: string,
  language: Language,
): BackofficeEndorsement[] {
  const endorsements: BackofficeEndorsement[] = []
  for (let i = 1; i < contract.versions.length; i++) {
    const changes = computeChanges(contract.versions[i - 1], contract.versions[i], language)

    endorsements.push({
      signatureId: contract.versions[i].signature.id,
      signatureTimestamp: contract.versions[i].signature.timestamp,
      startTimestamp: contract.versions[i].startTimestamp,
      paymentAmount: checkDefinedAndNotNull(
        contract.versions[i].dimensions.quote?.paymentRecurrence === 'yearly'
          ? contract.versions[i].dimensions.quote?.yearlyTotalPremium
          : contract.versions[i].dimensions.quote?.subsequentMonthsTotalPremium,
      ),
      paymentRecurrence: checkDefinedAndNotNull(contract.versions[i].dimensions.paymentRecurrence),
      fileName: dynamicFileNames['agreed-terms'](customerEmail, language),
      downloadLink: getAdminContractDownloadLink(contract.subscriptionId, contract.versions[i].signature.id),
      comment: contract.versions[i].comment,
      changes,
      contractVersion: contract.versions[i],
      subscriptionId: contract.subscriptionId,
      id: contract.versions[i].endorsementId,
    })
  }

  return endorsements
}
