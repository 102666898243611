import type { InvoiceValidationProblem } from '@orus.eu/backend/src/invoicing/services/invoice-lifecycle-service'
import { Button, useAsyncCallback } from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { useState, type ReactElement, type ReactNode } from 'react'
import { trpc } from '../../../client'

type GenerateInvoiceButtonProps = {
  invoiceId: string
  refetch: () => Promise<unknown>
  children?: ReactNode
}

/**
 * This button validates a draft invoice. After, it displays the result and cause the
 * refetch of invoices list, to make sure all information on the page is up-to-date.
 * @param invoiceId
 * @param children
 * @constructor
 */
export function ValidateInvoiceButton({ invoiceId, children, refetch }: GenerateInvoiceButtonProps): ReactElement {
  const [state, setState] = useState<'idle' | 'working'>('idle')

  const validateInvoice = useAsyncCallback(async () => {
    setState('working')

    const result = await trpc.invoices.validateDraftInvoice.mutate(invoiceId)

    alert(isSuccess(result) ? 'Facture validée' : getFailureExplanation(result.problem))
    await refetch()
  }, [invoiceId, refetch])

  return (
    <Button
      size="small"
      variant="primary"
      disabled={state !== 'idle'}
      onClick={validateInvoice}
      isLoading={state === 'working'}
    >
      {children}
    </Button>
  )
}
function getFailureExplanation(problem: InvoiceValidationProblem): string {
  switch (problem) {
    case 'non-draft':
      return "La facture n'est pas un brouillon (peut être validée ou supprimée dans un autre onglet)"
  }
}
