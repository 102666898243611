import type { ContractUserGeneratedEndorsementState } from '@orus.eu/backend/src/services/contract-endorsement/contract-endorsement-state'
import { getFileDisplayName } from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { memo, type ReactElement } from 'react'
import { formatDateTime } from '../../../../../../lib/format'
import { serializeTimelineId } from '../../timeline/id-serializer'
import type { BackofficeEndorsement } from '../contract-endorsements-block/endorsements-block.utils'
import { RenewalEndorsementTable, type RenewalEndorsementTableRowData } from './RenewalEndorsementTable'

export type EndorsementsTableProps = {
  endorsements: BackofficeEndorsement[]
  pendingEndorsementsData: ContractUserGeneratedEndorsementState[] | undefined
  CreateNewEndorsementButton: ReactElement
}

export const EndorsementsTable = memo<EndorsementsTableProps>(function EndorsementsTable({
  endorsements,
  pendingEndorsementsData,
  CreateNewEndorsementButton,
}): ReactElement {
  const { subscriptionId } = useParams({ strict: false })

  const formattedEndorsements = endorsements
    .slice()
    .reverse()
    .map((endorsement, index): RenewalEndorsementTableRowData => {
      return {
        dueDate: formatDateTime(endorsement.startTimestamp),
        subscriptionId: endorsement.subscriptionId,
        timelineId: endorsement.id
          ? serializeTimelineId({
              type: 'contract_version',
              id: {
                type: 'subsequent',
                id: endorsement.id,
              },
            })
          : undefined,
        paymentRecurrence: endorsement.paymentRecurrence,
        paymentAmount: endorsement.paymentAmount,
        status: { label: 'Actif', variant: 'success', icon: 'circle-check-regular' },
        downloadLink: endorsement.downloadLink,
        fileName: endorsement.fileName,
        downloadTooltip: `${getFileDisplayName(endorsement.fileName)} - Avenant ${endorsements.length - index}`,
        routeToDetailedSheet: endorsement.contractVersion.dimensions.rcdaSelected
          ? {
              to: '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
              params: { subscriptionId: endorsement.subscriptionId, versionId: endorsement.contractVersion.id },
            }
          : undefined,
      }
    })

  const formattedPendingEndorsements = (pendingEndorsementsData ?? []).map(
    (pendingEndorsement): RenewalEndorsementTableRowData => {
      return {
        dueDate: '',
        status: { label: 'En cours', variant: 'warning', icon: 'pen-regular' },
        routeToNavigateTo: `/bak/contracts/${subscriptionId}/endorsement/${pendingEndorsement.id}`,
      }
    },
  )

  return (
    <RenewalEndorsementTable
      tableData={[...formattedPendingEndorsements, ...formattedEndorsements]}
      emptyStateSubtitle="Aucun avenant disponible"
      CreateNewEndorsementButton={CreateNewEndorsementButton}
    />
  )
})
