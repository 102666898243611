import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { getFileDisplayName, useLanguage } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'
import { dynamicFileNames } from '../../../../../../lib/download-filenames'
import { getAdminContractDownloadLink } from '../../../../../../lib/download-links'
import { formatDateTime } from '../../../../../../lib/format'
import { serializeTimelineId } from '../../timeline/id-serializer'
import { RenewalEndorsementTable, type RenewalEndorsementTableRowData } from './RenewalEndorsementTable'

export const ContractVersionsTable = function ContractVersionsTable({
  contract,
  customerEmail,
}: {
  contract: BackofficeContractDescription
  customerEmail: string
}): ReactElement {
  const language = useLanguage()

  const tableData = contract.versions
    .slice()
    .reverse()
    .map((contractVersion, index): RenewalEndorsementTableRowData => {
      const fileName = dynamicFileNames['agreed-terms'](customerEmail, language)
      return {
        dueDate: formatDateTime(contractVersion.startTimestamp),
        subscriptionId: contract.subscriptionId,
        timelineId: contractVersion.endorsementId
          ? serializeTimelineId({
              type: 'contract_version',
              id: {
                type: 'subsequent',
                id: contractVersion.endorsementId,
              },
            })
          : serializeTimelineId({
              type: 'contract_version',
              id: {
                type: 'initial',
                id: contract.subscriptionId,
              },
            }),
        paymentAmount: checkDefinedAndNotNull(
          contractVersion.dimensions.quote?.paymentRecurrence === 'yearly'
            ? contractVersion.dimensions.quote?.yearlyTotalPremium
            : contractVersion.dimensions.quote?.subsequentMonthsTotalPremium,
        ),
        paymentRecurrence: checkDefinedAndNotNull(contractVersion.dimensions.paymentRecurrence),
        status: { label: 'Actif', variant: 'success', icon: 'circle-check-regular' },

        downloadLink: getAdminContractDownloadLink(contract.subscriptionId, contractVersion.signature.id),
        fileName,
        downloadTooltip: `${getFileDisplayName(fileName)} - Version ${contract.versions.length - index}`,
        routeToDetailedSheet: contractVersion.dimensions.rcdaSelected
          ? {
              to: '/bak/contracts/$subscriptionId/detailed-sheet/versions/$versionId',
              params: { subscriptionId: contract.subscriptionId, versionId: contractVersion.id },
            }
          : undefined,
      }
    })

  return <RenewalEndorsementTable tableData={tableData} emptyStateSubtitle="Aucun contrat disponible" />
}
