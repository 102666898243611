import type { EmittedInvoice } from '@orus.eu/backend/src/invoicing/views/invoice-model'
import type {
  InvoicePaymentStatus,
  InvoicingItem,
} from '@orus.eu/backend/src/invoicing/views/invoice-payment-status-view'
import { PARIS, getZonedDateTimeFromMillis } from '@orus.eu/calendar-date'
import { Chip, type ChipVariant } from '@orus.eu/pharaoh'
import type { FC, ReactElement } from 'react'
import { oneMinute, useCurrentTimestamp } from '../../../lib/use-current-timestamp'
import { PaymentStatusTag } from './payment-status-tag'

export type InvoicingItemStatusTagProps = {
  invoicingItem: InvoicingItem
}

/**
 * A tag that displays the payment status of an invoice
 * @param invoicingItem
 * @constructor
 */
export function InvoicingItemStatusTag({ invoicingItem }: InvoicingItemStatusTagProps): ReactElement {
  switch (invoicingItem.type) {
    case 'draft':
      return <DraftInvoiceStatusTag />
    case 'deleted':
      return <></>
    case 'emitted': {
      return <EmittedInvoicePaymentStatusTag invoicePaymentStatus={invoicingItem} />
    }
  }
}

type EmittedInvoicePaymentStatusTagProps = {
  invoicePaymentStatus: InvoicePaymentStatus
}

export function EmittedInvoicePaymentStatusTag({
  invoicePaymentStatus,
}: EmittedInvoicePaymentStatusTagProps): ReactElement {
  switch (invoicePaymentStatus.invoice.status) {
    case 'cancelled':
      return <CancelledStatusTag invoicePaymentStatus={invoicePaymentStatus} />
    case 'valid':
      return <ValidStatusTag invoicePaymentStatus={invoicePaymentStatus} />
  }
}

type UnpaidInvoicePaymentStatusTagProps = {
  invoice: EmittedInvoice
  status: 'failed' | 'refunded' | 'cancelled' | 'disputed'
}

const CancelledStatusTag: FC<{ invoicePaymentStatus: InvoicePaymentStatus }> = ({ invoicePaymentStatus }) => {
  switch (invoicePaymentStatus.paymentStatus) {
    case 'new':
      return (
        <Chip size="large" variant="danger" icon="ban-regular">
          Annulée
        </Chip>
      )
    case 'refunded':
      return (
        <Chip size="large" variant="danger" icon="ban-regular">
          Annulée (remboursée)
        </Chip>
      )
    case 'failed':
      return (
        <Chip size="large" variant="danger" icon="triangle-exclamation-solid">
          Annulée (après impayé)
        </Chip>
      )
    case 'cancelled':
      return (
        <Chip size="large" variant="danger" icon="ban-regular">
          Annulée (paiment annulé)
        </Chip>
      )
    case 'pending':
    case 'paid':
    case 'disputed':
      return (
        <Chip size="large" variant="danger" icon="triangle-exclamation-solid">
          Incohérente
        </Chip>
      )
  }
}

const ValidStatusTag: FC<{ invoicePaymentStatus: InvoicePaymentStatus }> = ({ invoicePaymentStatus }) => {
  switch (invoicePaymentStatus.paymentStatus) {
    case 'new':
      return (
        <Chip size="large" variant="informal" icon="arrow-up-right-regular">
          Validée
        </Chip>
      )
    case 'failed':
    case 'refunded':
    case 'disputed':
    case 'cancelled': {
      return (
        <UnpaidInvoicePaymentStatusTag
          invoice={invoicePaymentStatus.invoice}
          status={invoicePaymentStatus.paymentStatus}
        />
      )
    }

    case 'paid':
    case 'pending': {
      return <PaymentStatusTag paymentStatus={invoicePaymentStatus.paymentStatus} />
    }
  }
}

function UnpaidInvoicePaymentStatusTag({ invoice, status }: UnpaidInvoicePaymentStatusTagProps): ReactElement {
  const now = useCurrentTimestamp(oneMinute)
  const nowDateTime = getZonedDateTimeFromMillis(now, PARIS)
  const dueDateTime = getZonedDateTimeFromMillis(invoice.dueTimestamp, PARIS)
  const daysUnpaid = Math.floor(nowDateTime.diff(dueDateTime, 'day').days)
  const getFailedPaymentText = failedPaymentsStatusTextGetters[status]
  const variant = failedPaymentsStatusChipVariant[status]
  return (
    <Chip size="large" variant={variant} icon="triangle-exclamation-solid">
      {getFailedPaymentText(daysUnpaid)}
    </Chip>
  )
}

export function DraftInvoiceStatusTag(): ReactElement {
  return (
    <Chip size="large" icon="pen-regular">
      Brouillon
    </Chip>
  )
}

const failedPaymentsStatusTextGetters: {
  [key in 'failed' | 'refunded' | 'cancelled' | 'disputed']: (daysUnpaid: number) => string
} = {
  failed: (daysUnpaid) => {
    switch (daysUnpaid) {
      case 0:
        return 'Impayée'
      case 1:
        return 'Impayée | 1 jour'
      default:
        return `Impayée | ${daysUnpaid} jours`
    }
  },
  refunded: (daysUnpaid) => {
    switch (daysUnpaid) {
      case 0:
        return 'Remboursée'
      case 1:
        return 'Remboursée | 1 jour'
      default:
        return `Remboursée | ${daysUnpaid} jours`
    }
  },
  cancelled: (daysUnpaid) => {
    switch (daysUnpaid) {
      case 0:
        return 'Paiement annulé'
      case 1:
        return 'Paiement annulé | 1 jour'
      default:
        return `Paiement annulé | ${daysUnpaid} jours`
    }
  },
  disputed: (daysUnpaid) => {
    switch (daysUnpaid) {
      case 0:
        return 'Paiement contesté'
      case 1:
        return 'Paiement contesté | 1 jour'
      default:
        return `Paiement contesté | ${daysUnpaid} jours`
    }
  },
}

const failedPaymentsStatusChipVariant: { [key in 'failed' | 'refunded' | 'cancelled' | 'disputed']: ChipVariant } = {
  failed: 'danger',
  refunded: 'warning',
  cancelled: 'warning',
  disputed: 'danger',
}
