import { TableCell } from '@mui/material'
import type { Amount } from '@orus.eu/amount'
import { AmountText, primaryColor, Text } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'

export type TableCellAmountProps = {
  amount: Amount
}

export function TableCellAmount({ amount }: TableCellAmountProps): ReactElement {
  return (
    <TableCell>
      <Text variant="body2" color={primaryColor}>
        <AmountText amount={amount} displayDecimals={true} />
      </Text>
    </TableCell>
  )
}
